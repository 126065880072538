<template>
  <div class="tour-mgmt-wrapper">
    <div class="flex-between">
      <h1 class="page-title">투어 상품 관리</h1>
      <CruiseButton @click="goToRegisterPage">신규 등록</CruiseButton>
    </div>

    <div class="tour-header">
      <div class="header-title">투어 상품 현황</div>

      <ul class="summary flex-between">
        <li class="sum-list">
          <div class="sum-title">전체</div>
          <div class="sum-count">{{ totalTourCount }}건</div>
        </li>
        <li class="sum-list">
          <div class="sum-title">판매 중</div>
          <div class="sum-count">{{ onSaleTourSum }}건</div>
        </li>
        <li class="sum-list">
          <div class="sum-title">판매 대기</div>
          <div class="sum-count">{{ pendingTourSum }}건</div>
        </li>
        <li class="sum-list">
          <div class="sum-title">판매 종료</div>
          <div class="sum-count">{{ closedTourSum }}건</div>
        </li>
      </ul>
    </div>

    <div class="tour-search-filter">
      <div class="filter-list">
        <div class="filters">
          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(vesselResetOption, 'name')"
            :options="vesselList"
            v-model="searchState.vessel"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(routeResetOption, 'name')"
            :options="routeList"
            v-model="searchState.route"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(departurePortResetOption, 'name')"
            :options="portList"
            v-model="searchState.departurePort"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(arrivalPortResetOption, 'name')"
            :options="portList"
            v-model="searchState.arrivalPort"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>
        </div>

        <div class="filters">
          <CruiseInput class="input-width" placeholder="노출 상품명 입력" v-model="editableProductName" />
          <CruiseInput class="input-width" placeholder="등록 상품명 입력" v-model="searchState.productName" />
        </div>
      </div>

      <div class="filter-list">
        <div class="filters">
          <CruiseDatePicker
            class="input-width"
            v-model:dateRange="voyageDate"
            placeholder="시작 기간 - 종료 기간"
            range
          />
        </div>

        <div class="filters flex-center">
          <CruiseCheckbox class="checkbox-width" label="판매 중" v-model="searchState.statusOnSale" />
          <CruiseCheckbox class="checkbox-width" label="판매 대기" v-model="searchState.statusPending" />
          <CruiseCheckbox class="checkbox-width" label="판매 종료" v-model="searchState.statusClosed" />
        </div>
      </div>

      <div class="btn-wrapper">
        <CruiseButton class="clear-btn" theme="s" @click="reset">초기화</CruiseButton>
        <CruiseButton @click="getTourList()">검색</CruiseButton>
      </div>
    </div>

    <div class="tour-list-wrapper">
      <div class="header-title flex-between">
        <div class="header-title-text">
          투어 상품 현황 <span>총 {{ searchTourSum }}개</span>
        </div>
        <CruiseButton class="delete-btn" theme="q" @click="removeTourProductList">삭제</CruiseButton>
      </div>

      <div class="tour-table">
        <CruiseTable :theadList="thead" :tbodyList="tourList" @onAllCheck="handleAllCheck" @clickRow="goToUpdatePage">
          <template #checkbox="{ item }">
            <CruiseCheckbox v-model="item.isSelected" />
          </template>
          <template #editableProductName="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'name')">
              {{ getLocalText(item, 'name') }}
            </div>
          </template>
          <template #packagePrice="{ item }">
            <div class="text-ellipsis" :title="convertCommaNumber(item.packagePrice)">
              {{ convertCommaNumber(item.packagePrice) }}
            </div>
          </template>
          <template #tripType="{ item }">
            <div class="text-ellipsis" :title="getTripType(item.isRound)">
              {{ getTripType(item.isRound) }}
            </div>
          </template>
          <template #vessel="{ item }">
            <div class="text-ellipsis" :title="item.vessel.code">
              {{ item.vessel.code }}
            </div>
          </template>
          <template #departurePort="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item.departurePort, 'name')">
              {{ getLocalText(item.departurePort, 'name') }}
            </div>
          </template>
          <template #arrivalPort="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item.arrivalPort, 'name')">
              {{ getLocalText(item.arrivalPort, 'name') }}
            </div>
          </template>
          <template #targetGrades="{ item }">
            <div class="text-ellipsis" :title="getGradeCodes(item.gradeCodes)">
              {{ getGradeCodes(item.gradeCodes) }}
            </div>
          </template>
          <template #targetPeriod="{ item }">
            <div class="text-ellipsis" :title="formatDateRange(item.startDate, item.endDate)">
              {{ formatDateRange(item.startDate, item.endDate) }}
            </div>
          </template>
          <template #languages="{ item }">
            <div class="text-ellipsis" :title="getUsedLanguages(item)">
              {{ getUsedLanguages(item) }}
            </div>
          </template>
          <template #status="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'status')">
              {{ getLocalText(item, 'status') }}
            </div>
          </template>
          <template #remark="{ item }">
            <CruiseButton class="update-btn" theme="q" @click="goToUpdatePage(item)">수정</CruiseButton>
          </template>
        </CruiseTable>
      </div>
      <CruisePagination :page="activePage" :pages="totalPage" @update:page="handlePage" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { YYYYMMDD } from '@/const/const';
import { getLocalText, getLocalKey, convertCommaNumber, formatDate, formatDateRange } from '@/utils/convert';

import TourService from '@/services/tour';
import VesselService from '@/services/vessel';
import RouteService from '@/services/route';
import PortService from '@/services/port';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseDatePicker from '@/components/common/CruiseDatePicker.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';

export default {
  name: 'TourManagement',
  components: {
    CruiseButton,
    CruiseSelect,
    CruiseDatePicker,
    CruiseInput,
    CruiseTable,
    CruisePagination,
    CruiseCheckbox,
  },
  data() {
    return {
      tourList: [],
      vesselList: [],
      routeList: [],
      portList: [],

      vesselResetOption: { code: '', nameKo: '선박', nameJa: '船', nameEn: 'Vessel' },
      routeResetOption: { code: '', nameKo: '항로', nameJa: '航路', nameEn: 'Route' },
      departurePortResetOption: { code: '', nameKo: '출항지', nameJa: '出港地', nameEn: 'Port of Departure' },
      arrivalPortResetOption: { code: '', nameKo: '도착지', nameJa: '到着地', nameEn: 'Port of Arrival' },

      searchState: {
        vessel: '',
        route: '',
        departurePort: '',
        arrivalPort: '',
        startDate: '',
        endDate: '',
        statusOnSale: true,
        statusPending: true,
        statusClosed: true,
        productName: '',
        page: 1,
        limit: 10,
      },

      voyageDate: null,
      editableProductName: '',

      tripTypeList: [
        { code: true, name: '왕복' },
        { code: false, name: '편도' },
      ],

      totalTourCount: 0,
      onSaleTourSum: 0,
      pendingTourSum: 0,
      closedTourSum: 0,

      totalPage: 0,
      activePage: 1,
    };
  },
  computed: {
    thead() {
      return [
        { key: 'checkbox' },
        { label: '상품 ID', key: 'id', css: { width: '100px' } },
        { label: '노출 상품명', key: 'editableProductName', css: { width: '200px' } },
        { label: '등록 상품명', key: 'productName', css: { width: '160px' } },
        { label: '인당 추가 금액', key: 'packagePrice', css: { width: '120px' } },
        { label: '구분', key: 'tripType', css: { width: '80px' } },
        { label: '선박', key: 'vessel', css: { width: '80px' } },
        { label: '출항지', key: 'departurePort', css: { width: '100px' } },
        { label: '도착지', key: 'arrivalPort', css: { width: '100px' } },
        { label: '대상 객실', key: 'targetGrades', css: { width: '100px' } },
        { label: '대상 기간', key: 'targetPeriod', css: { width: '200px' } },
        { label: '간격', key: 'term', css: { width: '60px' } },
        { label: '노출 언어', key: 'languages', css: { width: '100px' } },
        { label: '상태', key: 'status', css: { width: '100px' } },
        { label: '', key: 'remark', css: { width: '60px' } },
      ];
    },
    searchTourSum() {
      return this.tourList ? this.tourList?.length : 0;
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,
    convertCommaNumber,
    formatDate,
    formatDateRange,

    async init() {
      try {
        this.setIsLoading(true);

        await this.getTourList();

        const responses = await Promise.all([
          VesselService.getVesselList(),
          RouteService.getRouteList(),
          PortService.getPortList(),
        ]);

        [this.vesselList, this.routeList, this.portList] = responses.map(response => response.data);
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTourList(page) {
      const nameByLocalText = getLocalKey('name');

      let [startDate, endDate] = ['', ''];
      if (this.voyageDate !== null) {
        [startDate, endDate] = this.voyageDate.map(date => this.formatDate(date, YYYYMMDD));
      }

      this.searchState = {
        ...this.searchState,
        startDate: startDate,
        endDate: endDate,
        [nameByLocalText]: this.editableProductName,
        page: page || 1,
      };

      try {
        this.setIsLoading(true);

        const { data } = await TourService.getTourProductList(this.searchState);

        this.tourList = data.packageTours?.map(tour => {
          return {
            ...tour.packageTour,
            gradeCodes: tour.gradeCodes,
          };
        });

        this.totalPage = data.pages;

        this.totalTourCount = data?.numbersOfEachStatus?.total || 0;
        this.onSaleTourSum = data?.numbersOfEachStatus?.onSale || 0;
        this.pendingTourSum = data?.numbersOfEachStatus?.pending || 0;
        this.closedTourSum = data?.numbersOfEachStatus?.closed || 0;
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    goToRegisterPage() {
      this.$router.push('/tour/create');
    },
    goToUpdatePage(tour) {
      this.$router.push({ path: '/tour/update', query: { id: tour.id } });
    },
    handleAllCheck(checked) {
      this.tourList?.forEach(tour => {
        tour.isSelected = checked;
      });
    },
    getTripType(isRound) {
      return this.tripTypeList.find(tripType => tripType.code === isRound).name;
    },
    getGradeCodes(gradeCodes) {
      return gradeCodes.join(', ');
    },
    getUsedLanguages(isOpenedKo, isOpenedJa, isOpenedEn) {
      return [isOpenedKo && '한', isOpenedJa && '일', isOpenedEn && '영'].filter(Boolean).join(', ');
    },
    async getVesselList() {
      try {
        const { data } = await VesselService.getVesselList();
        this.vesselList = data;
      } catch (error) {
        alert(error);
      }
    },
    async reset() {
      this.searchState = {
        vessel: '',
        route: '',
        departurePort: '',
        arrivalPort: '',
        startDate: '',
        endDate: '',
        statusOnSale: true,
        statusPending: true,
        statusClosed: true,
        nameKo: '',
        nameJa: '',
        nameEn: '',
        productName: '',
        page: 1,
        limit: 10,
      };

      this.voyageDate = null;
      this.editableProductName = '';

      await this.getTourList();
    },
    handlePage(page) {
      this.activePage = page;
      this.getTourList(page);
    },
    async removeTourProductList() {
      try {
        this.setIsLoading(true);

        const checkedList = this.tourList?.filter(tour => tour.isSelected).map(tour => tour.id);
        if (!checkedList.length) {
          alert('삭제할 상품을 선택해주세요.');
          return;
        }

        const params = {
          id: checkedList,
        };
        await TourService.removeTourProductList(params);

        alert('삭제되었습니다.');
        this.getTourList();
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.tour-mgmt-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tour-header {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 0 20px 20px;
  border: 0.5px solid #f0f0f0;
}

.tour-header .header-title {
  padding: 20px 0;
}

.summary,
.tour-search-filter {
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #dcdcdc;
  padding: 20px;
  background: #fff;
}

.sum-list {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  border-right: 1px solid #dcdcdc;
  padding: 20px 0;
  margin-right: 20px;
}

.sum-list:last-child {
  border-right: none;
  margin-right: 0;
}

.sum-title {
  font-size: 14px;
  font-weight: 400;
}

.sum-count {
  font-size: 24px;
  font-weight: 700;
}

.tour-search-filter {
  position: relative;
  display: flex;
  gap: 40px;
  border: 0.5px solid #f0f0f0;
}

.filter-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters {
  display: flex;
  gap: 10px;
  height: 100%;
}

.btn-wrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.clear-btn {
  margin-right: 10px;
}

.header-title-text > span {
  color: #969696;
  font-size: 16px;
  font-weight: 400;
  margin-left: 70px;
}

.input-width {
  width: 250px !important;
}

.select-width {
  flex-shrink: 0;
  width: 120px;
}

.checkbox-width {
  min-width: 90px;
}

.tour-list-wrapper {
  background: var(--White);
  min-height: 560px;
  border: 0.5px solid #f0f0f0;
}

.tour-table {
  min-height: 460px;
  overflow: auto;
}

.update-btn {
  width: 40px;
  height: 27px;
}
</style>
